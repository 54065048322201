



























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { ERRORTYPE } from '@projetlucie/lc-front-sdk-old';
import { NavButton } from '@/components/global';
import {
  LeaveEditRibModal,
  EditRibForm,
  InformationsSepa,
  SignaturePhone,
  SignatureModal,
  SuccessEditRibModal
} from '@/components';
import { pagePath } from '@/utils';
import { mandatSepa, contextEmployeur, toast } from '@/store';
import { BankInfo } from '@/models';

@Component({
  components: {
    NavButton,
    LeaveEditRibModal,
    EditRibForm,
    InformationsSepa,
    SignaturePhone,
    SignatureModal,
    SuccessEditRibModal
  }
})
export default class EditRib extends Vue {
  private refs: any = this.$refs;
  private ribInfo: BankInfo | null = null;
  private phone = '';
  private canContinue = false;
  private showModalLeave = false;
  private showModalSuccess = false;
  private ribIsValid = false;

  mounted() {
    this.ribInfo = this.bankInfo ?? this.ribInfo;
  }

  beforeDestroy() {
    mandatSepa.setRib(null);
  }

  get bankInfo() {
    return mandatSepa.rib;
  }

  get employeur() {
    return contextEmployeur.getEmployeur;
  }

  get sepaInformation() {
    return mandatSepa.getMandatSepa;
  }

  private async continu() {
    const isValidForm = await this.refs.rib.validate();
    if (!isValidForm) {
      this.canContinue = false;
      return;
    }
    if (!this.employeur?.contrat?.numeroContrat) {
      this.canContinue = false;
      toast.createAndPushToast('Merci de réessayer', "Une erreur s'est produite", true, ERRORTYPE.DANGER);
      return;
    }

    await mandatSepa.initMandatSepa(this.employeur.contrat.numeroContrat);
    this.canContinue = true;
  }

  private isFormValid(): boolean {
    return !(
      !this.employeur ||
      !this.employeur.numeroPersonne ||
      !this.employeur.numeroPersonneMorale ||
      !this.employeur.contrat?.numeroContrat ||
      !this.ribInfo ||
      !this.ribInfo.titulaire ||
      !this.ribInfo.iban ||
      !this.ribInfo.bic ||
      !this.phone
    );
  }

  private async submit() {
    const isValidForm = await this.refs.form.validate();

    if (!isValidForm || !this.isFormValid()) {
      return;
    }

    const proccessStarted = await mandatSepa.startProcessSignatureMandatSepa({
      numeroContrat: this.employeur?.contrat?.numeroContrat!,
      numeroPersonnePhysique: this.employeur?.numeroPersonne!,
      numeroPersonneMorale: this.employeur?.numeroPersonneMorale!,
      titulaire: this.ribInfo?.titulaire!,
      iban: this.ribInfo?.iban!,
      bic: this.ribInfo?.bic!,
      telephone: this.phone
    });

    if (!proccessStarted) {
      toast.createAndPushToast('Merci de réessayer', "Une erreur s'est produite", true, ERRORTYPE.DANGER);
      return;
    }

    this.openSignatureModale();
  }

  public openSignatureModale() {
    this.refs.signatureModal.setModalState(true);
  }

  private handleNav() {
    if (this.showModalLeave) {
      this.$router.push(pagePath.Cotisations);
    } else {
      this.showModalLeave = true;
    }
  }

  private checkUpdateRib(payload: BankInfo) {
    if (payload.bic && payload.iban && payload.titulaire) {
      this.ribInfo = payload;
      this.ribIsValid = true;
    } else {
      this.ribIsValid = false;
    }
  }

  private closeModalLeave(payload: boolean) {
    this.showModalLeave = payload;
  }

  private closeModalSuccess() {
    this.showModalSuccess = false;
  }

  private async signatureIsOK(payload: boolean) {
    if (!payload) {
      return toast.createAndPushToast('Merci de réessayer', "Une erreur s'est produite", true, ERRORTYPE.DANGER);
    }
    const addRIB = await mandatSepa.addRIB();
    if (!addRIB) {
      return toast.createAndPushToast('Merci de réessayer', "Une erreur s'est produite", true, ERRORTYPE.DANGER);
    }
    this.refs.signatureModal.setModalState(false);
    this.showModalSuccess = true;
  }

  @Watch('ribIsValid')
  private resetFormAtStart() {
    if (!this.ribIsValid) {
      this.canContinue = false;
    }
  }
}
